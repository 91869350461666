import logoSignIn from '../assets/fmi_logo.png'
import axios from 'axios';
import { amber, deepPurple, green, grey, indigo, lightBlue, orange, red, teal } from '@material-ui/core/colors'

export const nodata = 'N/D'

export const drawerWidth = 145;
export const closedDrawerWidth = 0;

export const imgServerS3 = "https://fmi-public.s3.us-east-2.amazonaws.com/"; // can be private instead of public
export const rfcImgServerS3 = "https://s3.us-east-2.amazonaws.com/asiconnect3-public/business/image";

export const mainServer = {
  instance: axios.create({
    baseURL: 'https://api.foundationxti.com/prod',
    headers: {'Content-Type': 'application/json'},
  }),
  credentials: {
    user: 'trionix',
    password: 'kv5wMkMxwfWD4za9Qrgjp6gXdmJ38x9L'
  }
}

export const SignIn = {
  logo: logoSignIn,
  spinner: {
    color: '#0c519d'
  }
}

export const SideBar = {
  width: drawerWidth,
  minWidth: closedDrawerWidth,
}

export const TopBar = {
  width: drawerWidth,
  minWidth: closedDrawerWidth,
}

export const Footer = {

}

export const palette = {
  primary:{
    main:'#f4511e'
  },
  secondary: { main: '#f4511e' },
}

export const Languages = [
  {id:2, code:'MX', language:'spanish'},
  {id:1, code:'US', language:'english'},
];

export const USER_TYPE = {
  ADMIN:1,
  ROCHE:2,
  REGISTRANT:3,
  AUDITOR:4,
}

export const PATIENT_STATUS = {
  INPROCESS:1,
  PREAUTHORIZED:2,
  AUTHORIZED:3,
  REJECTED:4,
  ACDQUIRED:5,
  //NOADCQUIRED:6,
  REPORTED:6,
  //NOREPORTED:8
}


export const APPROVAL_STATUS = {
  NOTSTARTED:1,
  PENDING:2,
  APPROVE:3,
  DISMISSED:4,
  CANCEL:5
}

export const PATIENT_STATUS_COLOR = {
  INPROCESS:lightBlue[700],
  PREAUTHORIZED:teal[700],
  AUTHORIZED:green[700],
  REJECTED:red[700],
  ACDQUIRED:indigo[700],
  NOADCQUIRED:amber[700],
  REPORTED:deepPurple[700],
  NOREPORTED:orange[700]
}



export const s3Server = {
  instance: axios.create({
    baseURL: "https://s3.us-east-2.amazonaws.com/asiconnect3-public/user/image",
    headers: {'Access-Control-Allow-Origin': '*'},
  }),
}
